<template>
  <!--  消费者专栏-->
  <div class="consumer">
    <crumbs :item="title"></crumbs>
    <main>
      <div class="title">普惠金融推进月专栏</div>
      <!-- 搜索 -->
      <div class="search">
        <el-input
          clearable
          v-model="value"
          placeholder="输入关键字搜索"
        ></el-input>
        <img @click="submit" src="~@/static/images/search.png" alt="" />
      </div>
      <ul class="main" v-loading="loading" element-loading-text="拼命加载中">
        <li v-for="item in list" :key="item.zid">
          <template v-if="!item.consumerType">
            <a @click="details(item.zid)">
              <!--              <div>-->
              {{ item.consumerTitle }}
              <!--              </div>-->
            </a>

            <span>{{ item.createdate }}</span>
          </template>
          <template v-else>
            <a v-if="item.consumerLink" :href="item.consumerLink" target="_blank">
              <!--              <div>-->
              {{ item.consumerTitle }}
              <!--              </div>-->
            </a>
            <a v-else >
              <!--              <div>-->
              {{ item.consumerTitle }}
              <!--              </div>-->
            </a>
            <span>{{ item.createdate }}</span>
          </template>
        </li>
      </ul>
      <div class="paging">
        <!-- <p v-if="tab == 1">共有{{ data.length }}条记录</p> -->
        <p>共有{{ total }}条记录</p>
        <div>
          <img
            @click="getPageNumAReduction()"
            src="~@/static/images/l_arrow@2x.png"
            alt=""
          />
          <span>{{ pageNum }}/{{ pages }}</span>
          <img
            @click="getPageNumAdd()"
            src="~@/static/images/ic_arrow_black@2x.png"
            alt=""
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { phFinancialPromotion } from "@/api/consumer";
import crumbs from "@/components/crumbs";
export default {
  name: "consumer",
  components: { crumbs },
  data() {
    return {
      title: [
        // {
        //   link: "",
        //   title: "产品服务",
        // },
        // {
        //   link: "",
        //   title: "其他服务",
        // },
        {
          link: "",
          title: "普惠金融推进月专栏",
        },
      ],
      loading: false,
      pages: 0,
      pageNum: 0,
      total: 0,
      list: [],
      value: "",
    };
  },
  created() {
    this.request();
  },
  mounted() {
  },
  methods: {
    submit() {
      console.log(this.value);
      this.request(this.pageNum, this.value);
    },
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.pageNum);
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.pageNum);
      }
    },
    details(zid) {
      console.log(111);
      console.log(zid);
      this.$router.push(`/preview/phFinancialPromotionDetails?zid=${zid}`);
    },
    async request(num,title) {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await phFinancialPromotion({
          timeStamp,
          sign,
          modelType: "pre",
          pageNum: num,
          consumerTitle:title,
        });
        console.log("普惠金融推进", res.data);
        if (res.data.code == 200) {
          console.log(res.data);
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          this.total = res.data.total;
          this.list = res.data.rows;
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
        this.loading = false;
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="less">
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.consumer {
  > main {
    width: 80%;
    margin: 5rem auto;
    //border: 1px solid;
    .title {
      font-size: 20px;
      text-align: center;
      margin-bottom: 3rem;
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: end;
      width: 80%;
      margin: auto;

      /deep/ .el-input__inner {
        border: none !important;
        width: 23rem !important;
        border-bottom: 1px solid !important;
        border-radius: 0 !important;
      }

      > img {
        width: 4rem;
        height: 4rem;
        margin-left: 1.5rem;
        cursor: pointer;
      }
    }
    .main {
      margin: 3rem 0;
      z-index: 1;
      height: 57rem;
      > li {
        width: 80%;
        margin: auto;
        height: 5rem;
        line-height: 5rem;
        font-size: 2.3rem;
        display: flex;
        justify-content: space-between;
        a {
          color: #003781;
          cursor: pointer;
        }
        > span {
          margin-right: 10rem;
        }
      }
    }
  }
  /deep/ .paging {
    width: 80%;
    margin: 3rem auto;
    padding: 0;
  }
}
::v-deep .popper-select {
  position: absolute !important;
  top: 35px !important;
  left: 0px !important;
}

::v-deep.el-input {
  width: 23rem !important;
}
</style>